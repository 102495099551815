<template>
  <div
    class="background-image-container"
    :style="`background-image: url('${backgroundImage.url}');`"
  ></div>
</template>

<script>
export default {
  name: 'BackgroundImage',
  props: {
    backgroundImage: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.background-image-container {
  @apply bg-cover bg-no-repeat;
  padding-top: 56.25%;
}
</style>
