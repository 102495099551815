<template>
  <div
    class="paragraph-background-image-container"
    :style="`background-image: url(${backgroundImageUrl});`"
  >
    <div class="container">
      <div>
        <p :inner-html.prop="containerData.paragraph | nl2br"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParagraphBackgroundImage',
  props: {
    containerData: {
      type: Object,
      required: true
    }
  },
  computed: {
    backgroundImageUrl() {
      return this.containerData ? this.containerData.backgroundImage[0].url : ''
    }
  },
  watch: {
    backgroundImageUrl(newVal) {
      if (!newVal.length) return
      this.preloadImage()
    }
  },
  mounted() {
    this.preloadImage()
  },
  methods: {
    preloadImage() {
      if (!this.backgroundImageUrl.length) return
      const preloader = document.createElement('img')
      preloader.addEventListener('load', () => {
        this.$emit('loaded')
      })
      preloader.src = this.backgroundImageUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.paragraph-background-image-container {
  @apply bg-cover bg-no-repeat bg-center;
  min-height: 400px;

  .container {
    @apply flex justify-center;

    div {
      @apply w-4/5;

      p {
        letter-spacing: 3px;
        font-size: 24px;
        padding: 80px 0;
        font-family: FuturaPtBook, serif;
      }
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      div {
        @apply w-2/3;
        p {
          font-size: 32px;
          padding: 160px 0;
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    min-height: 600px;

    .container {
      div {
        @apply w-2/3;
        p {
          font-size: 42px;
          padding: 160px 0;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .container {
      div {
        @apply w-2/5;
        p {
          padding: 160px 0;
        }
      }
    }
  }

  @media screen and (min-width: 1500px) {
    min-height: 900px;

    .container {
      div {
        @apply w-2/6;
        p {
          padding: 250px 0;
        }
      }
    }
  }

  @media screen and (min-width: 2000px) {
    min-height: 1100px;
    .container {
      div {
        p {
          padding: 300px 0;
        }
      }
    }
  }
}
</style>
