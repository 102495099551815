<template>
  <div
    class="story-hero-container"
    :style="
      `background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(82, 94, 110, 0.9)), url(${backgroundImageUrl});`
    "
  >
    <div class="container">
      <h2 v-html="titleText"></h2>

      <font-awesome-icon
        v-if="heroItem.videoUrl"
        class="play-icon"
        role="button"
        aria-label="Open Video Player"
        icon="play"
        @click="$modal.show(modalName)"
      />

      <h3
        ref="subtitle"
        v-observe-visibility="{
          callback: showSubtitle,
          once: true,
          intersection: {
            threshold: 0.3
          }
        }"
        v-html="subtitle"
      ></h3>
    </div>

    <ModalWrapper
      v-if="heroItem.videoUrl"
      class="video-modal"
      :modal-name="modalName"
      width="90%"
    >
      <vueVimeoPlayer
        ref="player"
        class="vimeo-player"
        :video-url="heroItem.videoUrl"
        :video-id="null"
        :options="vimeoOptions"
      />
    </ModalWrapper>
  </div>
</template>

<script>
import ModalWrapper from '@/components/ui/ModalWrapper'
import { vueVimeoPlayer } from 'vue-vimeo-player'
export default {
  name: 'StoryHeroContainer',
  components: { ModalWrapper, vueVimeoPlayer },
  props: {
    heroData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      vimeoOptions: {
        title: false,
        byline: false,
        responsive: true,
        portrait: false,
        autoplay: false,
        muted: false,
        loop: true
      }
    }
  },
  computed: {
    modalName() {
      return `story_hero_${this.heroData[0].id}_video`
    },
    heroItem() {
      // At this time we are not making a carousel. Just a single item for now.
      return this.heroData.length ? this.heroData[0] : null
    },
    titleText() {
      return this.heroItem ? this.heroItem.titleText : ''
    },
    subtitle() {
      return this.heroItem ? this.heroItem.subtitle : ''
    },
    backgroundImageUrl() {
      return this.heroItem ? this.heroItem.backgroundImage[0].url : ''
    }
  },
  watch: {
    backgroundImageUrl(newVal) {
      if (newVal.length) {
        this.preloadImage()
      }
    }
  },
  mounted() {
    this.preloadImage()
  },
  methods: {
    preloadImage() {
      if (!this.backgroundImageUrl.length) return

      const preloader = document.createElement('img')

      preloader.addEventListener('load', () => {
        this.$emit('loaded')
      })

      preloader.src = this.backgroundImageUrl
    },
    showSubtitle(isVisible, entry) {
      if (!isVisible) return

      entry.target.classList.add('shown')
    }
  }
}
</script>

<style lang="scss" scoped>
.story-hero-container {
  @apply bg-cover;
  @apply bg-no-repeat;
  @apply bg-center;

  h2 {
    font-size: 28px;
    line-height: 28px;
    color: #ffffff;
    padding-top: 150px;
    padding-bottom: 82px;
    letter-spacing: 5px;
  }

  .play-icon {
    color: rgba(255, 255, 255, 0.8);
    font-size: 180px;
    transition: all 0.3s ease;
    margin-bottom: 41px;

    &:hover {
      color: rgba(255, 255, 255, 0.95);
      transform: scale(1.1);
    }
  }

  h3 {
    @apply relative;
    @apply opacity-0;
    right: -5%;
    font-size: 17px;
    line-height: 17px;
    letter-spacing: 5px;
    color: #ffffff;
    padding-bottom: 100px;
    transition: right 1500ms ease-in, opacity 1500ms ease-in;

    &.shown {
      @apply opacity-100;
      @apply right-0;
    }
  }

  .video-modal {
    .vimeo-player {
      @apply py-4 px-4;
    }
  }

  @media screen and (min-width: 600px) {
    h2 {
      font-size: 56px;
      line-height: 56px;
      padding-top: 300px;
      padding-bottom: 165px;
    }

    h3 {
      font-size: 35px;
      line-height: 35px;
      padding-bottom: 200px;
    }

    .video-modal {
      .vimeo-player {
        @apply py-8 px-8;
      }
    }
  }

  @media screen and (min-width: 900px) {
    h2 {
      padding-top: 400px;
      padding-bottom: 220px;
    }

    h3 {
      padding-bottom: 267px;
    }
  }

  @media screen and (min-width: 1500px) {
    h2 {
      padding-top: 600px;
      padding-bottom: 330px;
    }

    h3 {
      padding-bottom: 400px;
    }
  }

  @media screen and (min-width: 2000px) {
    h2 {
      padding-top: 800px;
      padding-bottom: 442px;
    }

    h3 {
      padding-bottom: 542px;
    }
  }
}
</style>
