<template>
  <div id="milneStoryPage">
    <StoryHeroContainer
      v-if="heroData"
      @loaded="toggleShowPageTrue"
      :hero-data="heroData"
    />
    <HighlightsTowerContainer :highlights-tower-data="highlightsTowerData" />
    <ParagraphBackgroundImage
      v-if="paragraphBackgroundData"
      :container-data="paragraphBackgroundData"
    />
    <BackgroundImage
      v-if="backgroundImageData"
      class="background-image-container"
      :background-image="backgroundImageData"
    />
  </div>
</template>

<script>
import api from '@/api'
import viewMixin from '@/mixins/viewMixin'
import StoryHeroContainer from '@/components/Hero/StoryHeroContainer'
import ParagraphBackgroundImage from '@/components/ui/ParagraphBackgroundImage'
import BackgroundImage from '@/components/ui/BackgroundImage'
import HighlightsTowerContainer from '@/components/HighlightsTower/HighlightsTowerContainer'
import apiHelper from '@/api/helpers/helpers'

export default {
  name: 'MilneStory',
  components: {
    HighlightsTowerContainer,
    BackgroundImage,
    StoryHeroContainer,
    ParagraphBackgroundImage
  },
  mixins: [viewMixin],
  data() {
    return {
      heroData: null,
      highlightsTowerData: [],
      paragraphBackgroundData: null,
      backgroundImageData: null
    }
  },
  mounted() {
    this.loadMilneStoryPage()
  },
  methods: {
    loadMilneStoryPage() {
      this.setMilneStoryData(apiHelper.readStoredResponse('milneStory'))

      api.milneStory.loadMilneStoryPage().then(response => {
        this.setMilneStoryData(response)
      })
    },
    setMilneStoryData(response) {
      if (!response || !response.entry) return

      this.heroData = response.entry.storyHero || null
      this.highlightsTowerData = response.entry.highlightsTower || []
      this.paragraphBackgroundData =
        response.entry.paragraphBackgroundImage[0] || null
      this.backgroundImageData = response.entry.backgroundImage[0] || null
    }
  }
}
</script>

<style lang="scss" scoped>
#milneStoryPage {
  .background-image-container {
    box-shadow: 0 0 25px rgba(82, 94, 110, 62%);
  }
}
</style>
